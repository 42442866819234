@import url("https://unpkg.com/@tailwindcss/typography@0.4.x/dist/typography.min.css");
@import url("https://unpkg.com/tailwindcss@^1.0/dist/tailwind.min.css");

.prose img {
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
}

.home-name {
  font-size: 150px;
}

.text-shadow {
  text-shadow: 1px 1px 4px rgba(150, 150, 150, 0.8);
}

@font-face {
  font-family: "VelinoW01";
  font-display: swap;
  src: local("VelinoW01"),
    url(./fonts/VelinoW01.ttf) format("truetype");
}

@font-face {
  font-family: "Superclarendon";
  font-display: swap;
  src: local("Superclarendon"),
    url(./fonts/SuperclarendonLight.ttf) format("truetype");
}

@font-face {
  font-family: "Superclarendon";
  font-display: swap;
  font-weight: 900;
  src: local("Superclarendon"),
    url(./fonts/SuperclarendonRegular.ttf) format("truetype");
}

@font-face {
  font-family: "SequalSans";
  font-display: swap;
  src: local("SequalSans"),
    url(./fonts/SequelSans/SequelSansBookBody.otf) format("truetype");
}

@font-face {
  font-family: "SequalSans";
  font-display: swap;
  font-weight: 900;
  src: local("SequalSans"),
    url(./fonts/SequelSans/SequelSansHeavyHead.otf) format("truetype");
}

@font-face {
  font-family: "SequalSans";
  font-display: swap;
  font-weight: 100;
  src: local("SequalSans"),
    url(./fonts/SequelSans/SequelSansLightDisp.otf) format("truetype");
}
